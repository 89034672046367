.header { height: calc(5rem + 1px); padding: 0; background: $white; text-align: center; border-bottom: 1px solid $lightGray; position: relative; z-index: 1000; box-shadow: 0px 7px 8px 0px rgba($black, 0.18); }
.header .logo { display: block; position: absolute; left: 1rem; top: 1rem; width: 170px; z-index: 1010; }

/* Main navigation */

.main-nav { margin: 0; right: 0; top: 0; display: inline-block; z-index: 2950; position: relative; min-width: 45rem; max-width: 100%;  }
.main-nav > ul { margin: 0; display: flex; width: auto; position: relative; padding-right: 7rem; transform: translateX(4rem); justify-content: center; }
.main-nav > ul > li { margin: 0; background: none; padding: 0 0.3rem; }
.main-nav > ul > li > a { position: relative;	 color: $blue; font-size: 0.9em; font-weight: 400; letter-spacing: 0.008rem; display: block; line-height: 5rem; white-space: nowrap; padding: 0; text-decoration: none; }
.main-nav > ul > li > a:hover, .main-nav ul > li > a:active, .main-nav ul > li > a:focus { color: $yellow; }
.main-nav > ul > li.active > a > span { position: relative; }
.main-nav > ul > li.active > a > span:after { content: ""; display: block; width: 100%; height: 3px; background: $yellow; position: absolute; left: 0; bottom: -4px; }

.dropdown-trig { position: relative; }
.dropdown-trig:before { @include triangleTop(20px, 10px, $lightYellow); position: absolute; left: calc(50% - 1rem); transform: translateY(0); top: 100%; transition: none; visibility: hidden; opacity: 0; }
.dropdown-trig.dropdown-trig-active { color: $yellow; }
.dropdown-trig.dropdown-trig-active:before { visibility: visible; opacity: 1; transform: translateY(calc(-100% - 0.6rem)); transition: all 0.2s ease-in; }

.dropdown-trig:after { content: ""; display: inline-block; width: 1rem; height: 1rem; background: url('../img/chevron-down-gray.svg') center center no-repeat; background-size: 100% 100%; transform: translate(-0.2rem, 0.25rem); transition: transform 0.1s ease-in; }
.dropdown-trig.dropdown-trig-active:after { transform: translate(-0.2rem, 0.25rem) rotateX(180deg); }

.header-btns { position: absolute; right: 0; top: 0; display: flex; margin: 0; z-index: 3000; }
.header-btns > li { padding: 0; margin: 0; background: none; position: relative; }
.header-btns > li > a { background: $white; color: $blue; font-size: 0.9em; font-weight: 400; display: block; line-height: 5rem; height: calc(5rem + 1px); width: 5rem; border-bottom: 1px solid $lightGray; border-left: 1px solid $lightGray; text-decoration: none; padding: 0; }
.header-btns > li > a { &:hover, &:active, &:focus { background: $yellow; border-color: $yellow; color: $white; } }
.header-btns a.search-trigger, { color: $yellow; padding: 1.5rem 0; }
.header-btns a.contact-btn { color: $yellow; padding: 1.7rem 0; }
.header-btns a.search-trigger:after { display: none; }
.header-btns a.search-trigger .icon { width: 1.8rem; height: 1.8rem; }
.header-btns a.contact-btn .icon {  width: 1.5rem; height: 1.5rem; }
.header-btns a.lng-switch { &:hover, &:active, &:focus { &:after { background: url('../img/chevron-down-white.svg') center center no-repeat; } } }
.header-btns a.menu-trigger { display: none; padding: 1.4rem 0; position: relative; z-index: 1100; }
.header-btns a.menu-trigger .icon-close { width: 1rem; height: 1rem; margin-top: 0.15rem; }
.header-btns a.menu-trigger .icon-menu { width: 1.2rem; height: 1.2rem; }
.header-btns a.search-trigger:before, .header-btns a.lng-switch:before { left: calc(50% - 10px); top: calc(100% + 1px); }

.lng-switch-mobile { display: none; }

.dropdown { position: absolute; left: 0; margin-top: -0.6rem; width: 100%; display: none; z-index: 1150; text-align: left; background: $lightYellow; padding: 1rem 1.5rem 1rem 1.5rem; }
.dropdown-head { font-size: 1rem; font-weight: 700; margin-bottom: 0.75rem; text-transform: uppercase; white-space: nowrap; display: block; text-decoration: none; }
a.dropdown-head { color: $blue; }
a.dropdown-head:hover, a.dropdown-head:active, a.dropdown-head:focus { color: $yellow; text-decoration: underline; }
.dropdown-col-head span { white-space: nowrap; }
.dropdown-head .icon { width: 1.3rem; height: 1.3rem; margin: 0.18rem 0 0 0.2rem; }
.dropdown-head .icon-arrow-right-bottom { width: 1.1rem; height: 1.1rem; color: $yellow;  }
.dropdown-col-head { font-size: 0.9rem; color: $black; font-weight: 500; margin-bottom: 0.6rem; text-transform: uppercase; }
.dropdown-col-head .icon { width: 1rem; height: 1rem; margin: 0.15rem 0 0 0.2rem; color: $yellow; }

#zivot-dropdown { width: 90%; }

.dropdown-search, .dropdown-lng { width: auto; left: auto; right: 0; }
.dropdown-lng { width: 100%; left: auto; right: 0; text-align: center; }
.dropdown-lng .dropdown-nav li:last-child { margin-bottom: 0; }

.dropdown-narrow { width: auto; min-width: 13rem; }
.dropdown-narrow-wrap { position: relative; }

.dropdown-nav { @extend .list-unstyled; margin: 0; }
.dropdown-nav li { list-style: none; padding: 0; display: block; position: relative; margin: 0 0 0.6rem 0; }
.dropdown-nav li a { background: none; font-size: 0.9rem; font-weight: 400; letter-spacing: 0.008rem; line-height: 1.4; display: block; color: $blue; text-decoration: none; }
.dropdown-nav li a:hover, .dropdown-nav li a:active, .dropdown-nav li a:focus { color: $yellow; background: transparent; text-decoration: underline; }
.dropdown-nav li a .icon { width: 0.9rem; height: 0.9rem; margin: 0.2rem 0 0 0; }

.dropdown-cols { display: flex; flex-direction: row; }
.dropdown-col { flex: 1; padding-top: 1rem; border-top: 2px solid #e1d9c0; color: $blueGray; font-size: 0.8rem; }
.dropdown-col { flex: 1; padding-top: 1rem; border-top: 2px solid #e1d9c0; color: $blueGray; font-size: 0.8rem; }
.dropdown-cols:first-child .dropdown-col { border-top: none; padding-top: 0.35rem; }
.dropdown-col.dropdown-col-bg { max-width: 50%; margin-bottom: 0.7rem; padding-bottom: 1rem; }

.dropdown-col .btn { width: 100%; text-align: center; white-space: nowrap; background-color: $blue; border-color: $blue; color: $white; }
.dropdown-col .btn { &:hover, &:active, &:focus { background-color: $yellow; border-color: $yellow; } }
.dropdown-col { .btn:not(.btn-simple), .btn-fill, .btn-fill-external { padding-right: 2.5rem; } }
.dropdown-col .btn:not(:last-child) { margin-bottom: 1rem; }

.dropdown-soc-nets { @extend .list-unstyled; margin: 0; display: flex; }
.dropdown-soc-nets li:not(:last-child) { margin-right: 0.75rem; }
.dropdown-soc-nets a { color: $blue; display: block; &:hover, &:active, &:focus { color: $yellow; } }
.dropdown-soc-nets .icon  { width: 1.8rem; height: 1.8rem; }

.subdropdown:not(:last-child) { margin-bottom: 1rem; }


@media screen and (max-width: $xl) {
	.header .logo { width: 160px; }

	.main-nav { margin-left: -4rem; }

	.header-btns > li > a { width: 4.75rem; }
}

@media screen and (max-width: 1280px) {
	.header-btns > li > a { width: 4.5rem; }
}


@media screen and (max-width: $lg) {
	.header .logo { width: 150px; }

	.main-nav { padding-left: 1.5rem; }
	.main-nav > ul > li { padding: 0 0.15rem; }
	.main-nav > ul > li > a { font-size: 0.85rem; }

	.header-btns li a { width: 3.6rem; }
	.header-btns a.search-trigger { padding: 1.7rem 0; }
	.header-btns a.search-trigger .icon { width: 1.6rem; height: 1.6rem; }
}

@media screen and (min-width: 1080px) {
	.subdropdown { display: block !important; }
}

@media screen and (max-width: 1080px) {
	.header-btns a.menu-trigger { display: block; }

	.header { height: auto; min-height: 4rem; width: 100%; }
	.header .logo { width: 136px; left: 0.75rem; top: 0.7rem; }

	.main-nav { background: $lightYellow; z-index: 1050; width: 100%; height: auto; left: 100%; margin: 0; top: 4rem; padding: 1.75rem 1.5rem 1.5rem 1rem; position: absolute; transition: left 0.25s ease-in; }
	.main-nav > ul { width: 100%; height: 100%; display: block; text-align: left; transform: none; padding: 0; }
	.main-nav > ul > li { display: block; width: 100%; padding: 0; }
	.main-nav > ul > li > a { display: block; line-height: 1.5; padding: 0.6rem 0; font-size: 1.25rem; text-decoration: none; color: $blue; }
	.main-nav > ul > li > a:hover, .main-nav > li > a:active, .main-nav > li > a:focus { color: $yellow; }

	.menu-open { }
	.menu-open .main-nav { left: 0; }
	.menu-open .header-btns a.menu-trigger { background: lighten($lightYellow, 5%); border-bottom: none; border-left-color: $lightGray; }
	.menu-open .header-btns a.menu-trigger { .icon-close { color: $blue; } &:focus .icon-close { color: $blue; } }
	.menu-open .header-btns a.menu-trigger { &:hover, &:active { .icon-close { color: $yellow; } } }

	.menu-trigger .icon-close { display: none; }
	.menu-open .menu-trigger .icon-close { display: inline-block; }
	.menu-open .menu-trigger .icon-menu { display: none; }

	.header-btns li a { width: 3.5rem; height: 4rem; line-height: 4rem; }
	.header-btns a.search-trigger { padding: 1.25rem 0; }
	.header-btns a.contact-btn { padding: 1.35rem 0; }
	.header-btns a.search-trigger .icon { width: 1.5rem; height: 1.5rem; }
	.header-btns a.contact-btn .icon { width: 1.3rem; height: 1.3rem; }
	.header-btns a.search-trigger, .header-btns > li { position: static; }

	.lng-switch-wrap { display: none; }
	.main-nav > ul > .lng-switch-mobile { display: flex; border-top: 1px solid #e1d9c0; margin-top: 1.5rem; padding-top: 0.5rem; }
	.main-nav > ul > .lng-switch-mobile a:not(:first-child) { margin-left: 1.5rem; }
	.main-nav > ul > .lng-switch-mobile a.active { color: $yellow; }

	.dropdown-trig:after { transform: translate(-0.2rem, 0.15rem); }
	.dropdown-trig.dropdown-trig-active:after { transform: translate(-0.2rem, 0.15rem) rotateX(180deg); }
	.dropdown-trig:before { display: none; }

	.dropdown:not(.dropdown-search, .dropdown-lng) { position: relative; margin: 0; padding: 0 0 0 1rem; }
	.dropdown-search, .dropdown-lng { width: 100vw; margin: 0; }

	.dropdown-head { text-transform: none; font-size: 1.1rem; font-weight: 400; margin: 0; border-top: 1px solid #e1d9c0; padding: 0.6rem 0; }
	.dropdown-head .icon { width: 1rem; height: 1rem; margin-top: 0.5rem; }

	.dropdown-col-head { text-transform: none; font-size: 1.1rem; color: $blue; font-weight: 400; margin: 0; }
	.dropdown-col-head .icon { display: none; }

	.subdropdown-trig { &:hover, &:active { cursor: pointer; } }
	.subdropdown-trig, .subdropdown-head { border-top: 1px solid #e1d9c0; padding: 0.5rem 0; }
	.subdropdown-trig:after { content: ""; display: inline-block; width: 1rem; height: 1rem; margin: 0; background: url('../img/chevron-down-gray.svg') center center no-repeat; background-size: 100% 100%; transform: translate(-0.2rem, 0.15rem); transition: transform 0.1s ease-in; }
	.subdropdown-trig.subdropdown-trig-active:after { transform: translate(-0.2rem, 0.15rem) rotateX(180deg); }
	.subdropdown { padding-bottom: 0.5rem; padding-left: 1rem; display: none; }

	.dropdown-cols { flex-wrap: wrap; margin-bottom: 0.5rem; }
	.dropdown-col { flex: 0 0 100%; padding: 0; margin: 0; border: none; }
	.dropdown-col.dropdown-col-bg { background: none; padding: 0; margin: 0; }
	.dropdown-col:last-child { border-bottom: 1px solid #e1d9c0; }
	.dropdown-col p { margin-bottom: 0; }

	.dropdown-nav li { margin-bottom: 0; }
	.dropdown-nav li a { font-size: 1.1rem; padding: 0.6rem 0; }
	.dropdown-nav li a .icon { margin-top: 0.4rem; }

	.dropdown-lng .dropdown-nav li a { padding: 0; line-height: 1.7; height: auto; }

	.main-nav .dropdown .btn { padding: 0.6rem 0; margin: 0 !important; background: none; color: $blue; text-transform: none; font-size: 1.1rem; font-weight: 400; border: none; text-align: left; }
	.main-nav .dropdown .btn:hover, .main-nav .dropdown .btn:active, .main-nav .dropdown .btn:focus { background: none; color: $yellow; text-decoration: underline; }
	.main-nav .dropdown .btn-icon .icon { margin-top: 0.55rem; }

	.dropdown-soc-nets { margin-bottom: 0.5rem; }
}

@media screen and (max-width: 350px) {
	.header .logo { width: 126px; left: 0.6rem; }
	.header-btns li a { width: 3.15rem; }
}