.admin-text { font-family: "Open Sans", "Helvetica Neue", helvetica, arial, verdana, sans-serif; font-size: 13px !important; color: #606060; }

img.x-form-trigger { display: inline !important; }
div.pimcore_tag_multihref { background-color: #fff; }
.ib script { display: none !important; }
table.x-btn { line-height: normal !important; margin: 0 !important; padding: 0 3px 0 3px !important; }
table.x-btn td { line-height: normal !important; margin: 0 !important; padding: 0 !important; }
table.x-btn tr { border: none !important; }
.x-menu-list-item { line-height: normal !important; }

.pimcore_area_buttons_inner { display: flex; }
.pimcore_area_buttons { visibility: visible !important; }
.pimcore_area_buttons .pimcore_block_label { white-space: nowrap; }
.pimcore_editable.btn { border: none !important; background: none !important; }
.pimcore_editable.link { padding: 0 30px 0 0 !important; margin: 0 !important; }
.pimcore_editable.link .x-btn { z-index: 10; }
#pimcore_editable_numbers { width: 30%; }

.topic .pimcore_wysiwyg { width: 100% !important; }

.topic-content .general-items .pimcore_tag_areablock { width: 33.33333%; }
.boxes-check-list .pimcore_tag_areablock { width: 50%; }

.box-half-img .pimcore_tag_image { height: 100%; }

.columns .pimcore_tag_areablock { width: 100%; }

.timeline.editmode .timeline-item { justify-content: flex-start !important; }
.timeline .pimcore_tag_image { z-index: 10; }

/*========================= Editor =========================*/

.cke_panel_container { background: #fff !important; }

.cke_ltr { background: #fff !important; }
.cke_ltr ul li { background: none; padding: 0; }
.cke_panel_listItem p, .cke_panel_listItem h1, .cke_panel_listItem h2, .cke_panel_listItem h3, .cke_panel_listItem h4, .cke_panel_listItem h5, .cke_panel_listItem h6, .cke_panel_listItem pre { font-size: inherit; font-style: normal; font-family: sans-serif; line-height: 30px; color: #000 !important; margin: 0; padding: 0; text-shadow: none; }
.cke_panel_listItem a { color: #000 !important; }
.cke_panel_listItem a:hover { color: #000 !important; }

.cke_dialog { z-index: 100300 !important; }
.cke_notification_message { background: #f2f2f2; font: normal normal normal 13px Arial,Helvetica,Tahoma,Verdana,Sans-Serif; padding: 3px 5px; }

.pimcore_editable { line-height: normal !important; }
.pimcore_area_entry { margin: 0 !important; padding: 0 !important; }